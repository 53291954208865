<template>
    <div class="page-body edit-module-modal">
        <NoButtonModal :open="true">
            <div class="modal-content-header bottom-20">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px">{{
                    profileFunction ? 'Edit Form Label' : 'Add Form Label'
                }}</span>
            </div>
            <ValidationObserver ref="form">
                <form style="box-shadow: none" class="p-0" @submit.prevent="save">
                    <div v-if="!loading" class="d-flex flex-column align-items-center gap-4">
                        <div class="w-75 mx-4">
                            <Input
                                label="Label Name"
                                name="Label Name"
                                id="labelNameInput"
                                placeholder="Enter Label name"
                                inputClass="input-add-new-dashboard mt-3 input-class"
                                labelClass="class-label"
                                v-model="moduleDetails.label_name"
                                :input_style="'text-transform:capitalize'"
                                required
                            />
                        </div>
                        <span class="d-flex w-75 mx-4 mt-2">
                            <input
                                type="checkbox"
                                id="required"
                                class="d-flex add-new-app-label right-5 color"
                                v-model="moduleDetails.is_required"
                            />
                            <label class="margin-0 font-16 d-flex" for="required"
                                >Please select checkbox for required fields</label
                            >
                        </span>
                        <div class="my-4 d-flex justify-content-center" style="bottom: 10%">
                            <button type="submit" class="primary btn-save">Save</button>
                            <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">Cancel</button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
            <div v-if="loading" class="my-4 d-flex flex-column justify-content-center align-items-center loader-main">
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { ValidationObserver } from 'vee-validate';

    export default {
        name: 'EditSignupFieldModal',
        components: { NoButtonModal, ValidationObserver },
        props: {
            profileFunction: {
                type: Object,
            },
        },
        data() {
            return {
                loading: true,
                showImgLoader: false,
                uploadedImg: null,
                moduleDetails: {
                    label_name: '',
                    is_required: false,
                    is_active: true,
                },
            };
        },
        methods: {
            async save() {
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }
                this.$emit('save', this.moduleDetails);
            },
        },
        async created() {
            if (this.profileFunction) {
                this.moduleDetails = { ...this.profileFunction };
            }
            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        background-color: #f1f7ff;
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .color {
        accent-color: var(--base-theme-tab-color);
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .edit-module-modal .modal__content {
        padding: 0;
        border-radius: 10px;
        min-height: fit-content;
        width: 35vw !important;
    }

    .edit-module-modal .input-class,
    #labelNameInput {
        box-shadow: inset 0 0 0 1px #707070;
        border: none !important;
        border-radius: 5px !important;
    }
</style>
