<template>
    <div class="page-body edit-module-modal">
        <NoButtonModal :open="true">
            <div class="modal-content-header bottom-20">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px">{{
                    profileFunction ? 'Edit Slide' : 'Add Slide'
                }}</span>
            </div>
            <ValidationObserver ref="form">
                <form style="box-shadow: none" class="p-0">
                    <div v-if="!loading" class="d-flex flex-column align-items-center gap-4">
                        <div class="w-75 mx-4 app-img-upload">
                            <label for="file" class="class-label w-50">Slide Image/ Video*</label>
                            <div class="input-add-new-dashboard mt-3 d-flex align-items-center input-class">
                                <span class="px-3" style="color: #747373; flex: 1"> Upload Image/ Video </span>
                                <button
                                    v-if="showImgLoader"
                                    type="button"
                                    class="no-bg text-button choose-file-btn my-1 mx-2 py-2 d-flex gap-2"
                                >
                                    <span> Uploading</span>
                                    <SpinLoader />
                                </button>
                                <button
                                    v-else
                                    type="button"
                                    class="no-bg text-button choose-file-btn my-1 mx-2 py-2"
                                    @click="chooseFile"
                                >
                                    Choose file
                                </button>
                                <input
                                    id="file"
                                    type="file"
                                    name="upload"
                                    ref="upload"
                                    size="100000"
                                    @change="uploadFile"
                                    hidden
                                    accept=".png, .jpg, .jpeg, .mp4"
                                    :disabled="showImgLoader"
                                />
                            </div>
                        </div>
                        <!-- <div class="my-2 d-flex justify-content-center align-items-center">
                            <div class="or-bg"></div>
                            <div class="or-circle">OR</div>
                        </div>
                        <div class="my-2 d-flex justify-content-center align-items-center choose-gallery">
                            Choose from gallery
                        </div> -->
                        <div class="w-75 mx-4 field-container d-flex flex-column">
                            <label for="description" class="class-label" style="padding-right: 5%"
                                >Slide Description*
                            </label>
                            <TextArea
                                id="description"
                                class="my-2 input-text"
                                type="text"
                                name="Description"
                                label=""
                                :placeholder="'Write Description'"
                                style="border-radius: 8px !important"
                                v-model="moduleDetails.description"
                                required
                            />
                        </div>
                        <div class="my-4 d-flex justify-content-center" style="bottom: 10%">
                            <button type="button" class="primary btn-save" @click="save" :disabled="showImgLoader">
                                Save
                            </button>
                            <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">Cancel</button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
            <div v-if="loading" class="my-4 d-flex flex-column justify-content-center align-items-center loader-main">
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import SpinLoader from '@/components/general/loading/SpinLoader.vue';
    import { ValidationObserver } from 'vee-validate';
    import { memberProfile } from '@/util/apiRequests';

    export default {
        name: 'EditIntroSlideModal',
        components: { NoButtonModal, ValidationObserver, SpinLoader },
        props: {
            profileFunction: {
                type: Object,
            },
        },
        data() {
            return {
                loading: true,
                showImgLoader: false,
                uploadedImg: null,
                moduleDetails: {
                    description: '',
                    file_id: null,
                    is_active: true,
                },
            };
        },
        methods: {
            chooseFile() {
                if (this.$refs.upload != undefined) {
                    this.$refs.upload.click();
                }
            },
            async uploadFile(event) {
                if (event.target.files.length > 0) {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileSize = file.size;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];
                    if (!['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG', 'mp4', 'MP4'].includes(fileExtension)) {
                        this.$toasted.error('Only png, jpg/jpeg, mp4 files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }

                    if (fileSize / 1024 / 1024 > 10) {
                        this.$toasted.error('File size should be less than 10MB');
                        this.$refs.upload.value = '';
                        return;
                    }

                    // HTTP trigger for File upload starts
                    try {
                        this.showImgLoader = true;
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async (event) => {
                            this.uploadedImg = event.target.result;
                            const formData = new FormData();
                            formData.append('file', file);
                            const endpoint = memberProfile.uploadProfileFunctionsImage();
                            const uploadImgResponse = await this.$api.post(endpoint, formData);
                            if (!uploadImgResponse.data.success) {
                                throw uploadImgResponse.data.error;
                            }
                            this.moduleDetails.file_id = uploadImgResponse.data.s3_link;
                            this.showImgLoader = false;
                            this.$toasted.success('File uploaded successfully !');
                        };
                    } catch (e) {
                        this.showImgLoader = false;
                        this.$refs.upload.value = '';
                        const errorMessage = e ? e : 'File upload failed !';
                        this.$toasted.error(errorMessage);
                    }
                }
            },
            async save() {
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }
                if (!this.moduleDetails?.file_id) {
                    this.$toasted.error('Please upload image/ video');
                    return;
                }
                this.$emit('save', this.moduleDetails);
            },
        },
        async created() {
            if (this.profileFunction) {
                this.moduleDetails = { ...this.profileFunction };
            }
            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        background-color: #f1f7ff;
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .choose-file-btn,
    .choose-file-btn:hover {
        width: 9rem;
        border-radius: 5px;
        background: #5155c3 !important;
        color: white !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .or-circle {
        z-index: 1;
        width: 20px;
        height: 20px;
        padding: 7px;
        font-weight: 600;
        border-radius: 50%;
        position: absolute;
        background: white;
        border: 2px solid #e2e2e2;
    }

    .or-bg {
        width: 25rem;
        height: 2px;
        background-color: #e2e2e2;
    }

    .choose-gallery {
        text-decoration: underline;
        color: #5155c3;
        font-size: 1.2rem;
        font-weight: 600;
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .edit-module-modal .modal__content {
        padding: 0;
        border-radius: 10px;
        min-height: fit-content;
        width: 35vw !important;
    }

    .edit-module-modal .input-class,
    #labelNameInput {
        box-shadow: inset 0 0 0 1px #707070;
        border: none !important;
        border-radius: 5px !important;
    }
</style>
