<template>
    <div class="page-body edit-module-modal">
        <NoButtonModal :open="true">
            <div class="modal-content-header bottom-20">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px">Edit Module</span>
            </div>
            <ValidationObserver ref="form">
                <form style="box-shadow: none" class="p-0" @submit.prevent="save">
                    <div v-if="!loading" class="d-flex flex-column align-items-center gap-4">
                        <div class="w-75 mx-4 app-img-upload">
                            <label for="file" class="class-label">Module Icon*</label>
                            <div class="input-add-new-dashboard mt-3 d-flex align-items-center input-class">
                                <span class="px-3" style="color: #747373; flex: 1"> Upload Image </span>
                                <button
                                    type="button"
                                    class="no-bg text-button choose-file-btn my-1 mx-2 py-2"
                                    @click="chooseFile"
                                >
                                    Choose file
                                </button>
                                <input
                                    id="file"
                                    type="file"
                                    name="upload"
                                    ref="upload"
                                    size="100000"
                                    @change="uploadFile"
                                    hidden
                                    accept=".png, .jpg, .jpeg"
                                    :disabled="showImgLoader"
                                />
                                <div
                                    class="img-container-small d-flex justify-content-center align-items-center m-1"
                                    style="margin-right: 20px !important"
                                >
                                    <svg
                                        width="20"
                                        height="35"
                                        viewBox="0 0 71 55"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.19815 8.79779H59.9507C60.833 8.79779 61.6211 9.15091 62.1943 9.71533C62.7663 10.2798 63.1251 11.08 63.1251 11.9263V51.8709C63.1251 52.7412 62.7663 53.5175 62.1943 54.0825C62.1469 54.1292 62.0984 54.1764 62.0273 54.2231C61.4778 54.7175 60.7388 54.9994 59.927 54.9994H3.17446C2.29157 54.9994 1.50345 54.6469 0.930843 54.0825C0.358239 53.518 0 52.7184 0 51.8715V11.9269C0 11.0566 0.358239 10.2803 0.930843 9.7159C1.50345 9.15148 2.31526 8.79836 3.17446 8.79836H3.19815V8.79779ZM16.3045 19.6431C18.8202 19.6431 20.8587 21.6525 20.8587 24.1323C20.8587 26.6121 18.8202 28.622 16.3045 28.622C13.7887 28.622 11.7502 26.6121 11.7502 24.1323C11.7496 21.653 13.7887 19.6431 16.3045 19.6431ZM35.4708 38.9092L44.5805 23.3845L54.2575 47.5122L9.09348 47.5116V44.5192L12.8897 44.333L16.6841 35.1672L18.5805 41.7148H24.2747L29.2074 29.1824L35.4708 38.9092ZM12.6487 3.71629C11.5988 3.71629 10.7633 2.86995 10.7633 1.85786C10.7633 0.822995 11.6225 0 12.6487 0H69.1152C70.1645 0 71 0.846916 71 1.85843V41.0974C71 42.1328 70.1414 42.9564 69.1152 42.9564C68.0648 42.9564 67.2292 42.1094 67.2292 41.0974V3.74021H12.6487V3.71629ZM59.3538 12.5152H3.79444V51.2598H59.3532L59.3538 12.5152Z"
                                            fill="black"
                                        />
                                    </svg>

                                    <img
                                        v-if="uploadedImg && !showImgLoader"
                                        :src="uploadedImg"
                                        alt="image not uploaded"
                                    />

                                    <div class="d-flex flex-column" v-if="showImgLoader" style="margin-top: 15px">
                                        <div class="d-flex justify-content-center align-items-center img-loader-main">
                                            <b-spinner variant="primary" class="img-loader"></b-spinner>
                                        </div>
                                        <span class="img-loader-text">Uploading...</span>
                                    </div>
                                    <ejs-tooltip
                                        class="tooltipcontainer"
                                        content="Delete"
                                        target="#target"
                                        cssClass="customtooltip"
                                        position="top"
                                    >
                                        <span
                                            id="target"
                                            class="material-symbols-outlined img-delete-small"
                                            role="button"
                                            @click="resetFileUpload()"
                                        >
                                            delete
                                        </span>
                                    </ejs-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="w-75 mx-4">
                            <Input
                                label="Module Name"
                                name="Module Name"
                                id="moduleNameInput"
                                placeholder="Enter Module name"
                                inputClass="input-add-new-dashboard mt-3 input-class"
                                labelClass="class-label"
                                v-model="moduleDetails.secondary_function_name"
                                :input_style="'text-transform:capitalize'"
                                required
                            />
                        </div>
                        <div class="my-4 d-flex justify-content-center" style="bottom: 10%">
                            <button type="submit" class="primary btn-save" :disabled="showImgLoader">Save</button>
                            <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">Cancel</button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
            <div v-if="loading" class="my-4 d-flex flex-column justify-content-center align-items-center loader-main">
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { ValidationObserver } from 'vee-validate';
    import { memberProfile, dashboardBuilder } from '@/util/apiRequests';

    export default {
        name: 'EditProfileFunctionModal',
        components: { NoButtonModal, ValidationObserver },
        props: {
            profileFunction: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: true,
                showImgLoader: false,
                uploadedImg: null,
                moduleDetails: {
                    secondary_function_name: '',
                    image: null,
                },
            };
        },
        methods: {
            async geturl(fileId) {
                const endpoint = dashboardBuilder.getUrls();
                const requestData = {
                    fileIds: [fileId],
                };
                let response = await this.$api.post(endpoint, requestData);
                return response?.data?.data[0].image;
            },
            chooseFile() {
                if (this.$refs.upload != undefined) {
                    this.$refs.upload.click();
                }
            },
            resetFileUpload() {
                this.uploadedImg = '';
                this.moduleDetails.image = '';
            },
            async uploadFile(event) {
                if (event.target.files.length > 0) {
                    const file = event.target.files[0];
                    const fileName = file.name;
                    const fileSize = file.size;
                    let fileNameSplit = fileName.split('.');
                    let fileExtension = fileNameSplit[1];
                    if (!['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].includes(fileExtension)) {
                        this.$toasted.error('Only png, jpg/jpeg files are allowed');
                        this.$refs.upload.value = '';
                        return;
                    }

                    if (fileSize / 1024 / 1024 > 10) {
                        this.$toasted.error('Image size should be less than 10MB');
                        this.$refs.upload.value = '';
                        return;
                    }

                    // HTTP trigger for File upload starts
                    try {
                        this.showImgLoader = true;
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async (event) => {
                            this.uploadedImg = event.target.result;
                            const formData = new FormData();
                            formData.append('file', file);
                            const endpoint = memberProfile.uploadProfileFunctionsImage();
                            const uploadImgResponse = await this.$api.post(endpoint, formData);
                            if (!uploadImgResponse.data.success) {
                                throw uploadImgResponse.data.error;
                            }
                            this.moduleDetails.image = uploadImgResponse.data.s3_link;
                            this.moduleDetails.imageUrl = await this.geturl(uploadImgResponse.data.s3_link);
                            this.showImgLoader = false;
                            this.$toasted.success('Image uploaded successfully !');
                        };
                    } catch (e) {
                        this.showImgLoader = false;
                        this.$refs.upload.value = '';
                        const errorMessage = e ? e : 'Image upload failed !';
                        this.$toasted.error(errorMessage);
                    }
                }
            },
            async save() {
                const form = this.$refs.form;
                const isFormValid = await form.validate();
                if (!isFormValid) {
                    this.$toasted.error('Please fill all required fields');
                    return;
                }
                if (!this.moduleDetails?.image) {
                    this.$toasted.error('Please upload image');
                    return;
                }
                this.$emit('save', this.moduleDetails);
            },
        },
        async created() {
            if (this.profileFunction) {
                this.moduleDetails = { ...this.profileFunction };
                this.uploadedImg = this.moduleDetails.imageUrl;
            }
            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        background-color: #f1f7ff;
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .choose-file-btn,
    .choose-file-btn:hover {
        width: 9rem;
        border-radius: 5px;
        background: #5155c3 !important;
        color: white !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .edit-module-modal .modal__content {
        padding: 0;
        border-radius: 10px;
        min-height: fit-content;
        width: 35vw !important;
    }

    .edit-module-modal .class-label {
        color: #666c6d;
        font-size: 1rem;
        font-weight: 600;
    }

    .edit-module-modal .input-class,
    #moduleNameInput {
        box-shadow: inset 0 0 0 1px #707070;
        border: none !important;
        border-radius: 5px !important;
    }
</style>
