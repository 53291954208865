<template>
  <div class="circular-carousel">
    <div class="carousel-container">
      <div class="carousel-image">
        <div class="bg-white rounded-4 border dash-builder">

          <div class="section-header-dashboardbuilder d-flex justify-content-between align-items-center ps-4 pe-2 py-2">
              <span class="label-app-list-header">Choose Template {{ currentId.id }}</span>
              <input v-model="selectedtemplate" type="radio" :value="currentId.id" :name="currentId.id" @change="emitSelection" />
          </div>
          <div class='droppable-template' v-if="pageData.page4.templateStyles">
                    <div :style="pageData.page4.templateStyles.primeContainer">
                        <div class="" :style="pageData.page4.templateStyles.bgImageContainer">
                            <img class="" :style="pageData.page4.templateStyles.bgImage"
                                :src="pageData.page4.templateStyles.bgImageUrl" width="100" height="100" />
                        </div>
                        <div class='t-m-c' :style="pageData.page4.templateStyles.templateMainContainer">
                            <div class='u-l-c' :style="pageData.page4.templateStyles.upperLayoutContainer">
                                <div v-for="(item, index) in Object.values(pageData.page4.template.LI)"
                                    :style="pageData.page4.templateStyles.upperLayoutContainerItemCommon + item.style"
                                    :key="item.name + ''">
                                    
                                    
                                    <draggable class="draggable-list" :list="item.elements" v-if="index > 1"
                                        :group="{ name: 'myGroup', pull: false, put: true }"
                                        @change="manipulateWidgets($event, 'LI', item.name, 'add')"
                                        :options="{ accept: false }">
                                        <div :class="element.class" v-for="element in item.elements" :key="element.name">
                                            <!-- {{ element.name }}  -->
                                            <img :src="element.image" alt="" style="height: 100%; width: 100%;">
                                            <!-- <button
                                                @click="manipulateWidgets($event, 'LI', item.name, 'remove', element)">Remove</button> -->
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <div class='g-s-c' :style="pageData.page4.templateStyles.gridSliderContainer">
                                <div v-for="item in Object.values(pageData.page4.template.GSI)" :class='item.class'
                                    :key="item.name + ''"
                                    :style="pageData.page4.templateStyles.gridSliderContainerItemCommon">
                                    <draggable class="draggable-list" :list="item.elements"
                                        :group="{ name: 'myGroup', pull: false, put: true }"
                                        @change="manipulateWidgets($event, 'GSI', item.name, 'add')">
                                        <div :class="element.class" v-for="element in item.elements" :key="element.name">
                                            <!-- {{ element.name }} -->
                                            <img :src="element.image" alt="" style="height: 100%; width: 100%;">
                                            <!-- <button
                                                @click="manipulateWidgets($event, 'GSI', item.name, 'remove', element)">Remove</button> -->
                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <div :style='pageData.page4.templateStyles.footerContainer' class="">
                                <div :style="pageData.page4.templateStyles.footerWeatherInfo" class="">
                                    <div class="sun-icon"></div>
                                    <div class="weather-text">Sunny Miami Florida</div>
                                </div>
                                <div class="" :style="pageData.page4.templateStyles.footerTabletStats">
                                    <div class="bottom-tablet-stats-icons">Bluetooth</div>
                                    <div class="bottom-tablet-stats-icons">Wifi</div>
                                    <div class="bottom-tablet-stats-icons">Online</div>
                                    <div class="bottom-tablet-stats-icons">Battery</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
      </div>
      <button class="prev-button rounded-circle" @click="prevImage">&#8249;</button>
      <button class="next-button rounded-circle" @click="nextImage">&#8250;</button>
    </div>
  </div>
</template>

<script>

import draggable from "vuedraggable";

export default {
  name: 'CircularCarousel',
  components: {
    draggable,
  },
  props: {
    templateData: {
      type: Array,
      required: true
    },
    pageData: {
      type: Object
    },
    selectedOption:{
      type:Number,
    },
    applicationFrame:{
      type:String
    }
        
  },
  data() {
    return {
      selectedtemplate: this.selectedOption,
      application:'',
      currentIndex: 0,
    };
  },
  computed: {
    currentId() {
      return this.templateData[this.currentIndex];
    }

  },
  methods: {
    prevImage() {
      this.currentIndex = (this.currentIndex - 1 + this.templateData.length) % this.templateData.length;
    },
    nextImage() {
      this.currentIndex = (this.currentIndex + 1) % this.templateData.length;
    },
    emitSelection() {
      this.$emit('selection-changed', this.selectedtemplate);
    },
  },
  
};
</script>

<style scoped>
.carousel-container {
  position: relative;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.carousel-image {
  position: relative;
  overflow: hidden;
}

.carousel-image img {
  width: 100%;
  display: block;
  transition: transform 0.5s ease-in-out;
}

.prev-button {
  position: absolute;
  top: 50%;
  transform: translateX(-145%);
  background-color: white;
  border: none;
  padding: 8px 16px;
  font-size: 20px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
  cursor: pointer;

}

.next-button {
  position: absolute;
  top: 50%;
  transform: translateX(145%);
  background-color: white;
  border: none;
  padding: 8px 16px;
  font-size: 20px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.24), inset 0px 4px 4px rgba(220, 220, 220, 0.25);
  cursor: pointer;

}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}
</style>
