<template>
    <div class="profile-function-card d-flex justify-content-between align-items-center">
        <span
            class="profile-function-card-label me-3 cursor-pointer profile-function-card-name"
            @click="showSingupEditModal = true"
            >{{ profileFunction.label_name }}</span
        >
        <Toggle
            :id="profileFunction.label_name"
            class="toggle-switch"
            labelClass="switch"
            spanClass="slider"
            :value="profileFunction.is_active"
            @input="(value) => handleToggle(value, profileFunction)"
        />
        <EditSignupFieldModal
            v-if="showSingupEditModal"
            :profileFunction="profileFunction"
            @save="handleSignupField"
            @cancel="showSingupEditModal = false"
        />
    </div>
</template>

<script>
    import EditSignupFieldModal from './EditSignupFieldModal.vue';

    export default {
        name: 'SignupFieldCard',
        components: { EditSignupFieldModal },
        props: {
            profileFunction: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showSingupEditModal: false,
            };
        },
        methods: {
            handleToggle(value, profileFunction) {
                this.$emit('onToggle', { value, profileFunction });
            },
            handleSignupField(data) {
                this.$emit('handleSignupField', data);
                this.showSingupEditModal = false;
            },
        },
    };
</script>

<style scoped>
    .profile-function-card-name {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        color: #373cbb;
        text-transform: capitalize;
    }
</style>
