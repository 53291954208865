<template>
    <div class="profile-function-card d-flex gap-4 justify-content-between px-5" style="max-width: 100%">
        <img
            v-if="profileFunction.file_id.toLowerCase().endsWith('.mp4')"
            src="@/assets/px/mobex-health-plus-dashboard/video.svg"
            alt="video"
            title="video"
            width="30"
        />
        <img v-else src="@/assets/px/mobex-health-plus-dashboard/image.svg" alt="Image" title="Image" width="30" />
        <span class="profile-function-card-label me-3 cursor-pointer profile-function-card-name w-70">{{
            profileFunction.description
        }}</span>
        <Toggle
            :id="profileFunction.description"
            class="toggle-switch"
            labelClass="switch"
            spanClass="slider"
            :value="profileFunction.is_active"
            @input="(value) => handleToggle(value, profileFunction)"
        />
        <div class="d-flex justify-content-center align-items-center" style="gap: 10px">
            <ejs-tooltip class="tooltipcontainer" content="Edit" target="#target" cssClass="customtooltip">
                <img
                    src="@/assets/px/edit-icon.png"
                    alt=""
                    style="height: 20px"
                    id="target"
                    @click="showIntroSlidesEditModal = true"
                />
            </ejs-tooltip>
            <ejs-tooltip class="tooltipcontainer" content="Delete" target="#target" cssClass="customtooltip">
                <img
                    src="@/assets/px/delete-icon.png"
                    alt=""
                    style="height: 20px"
                    id="target"
                    @click="showIntroSlideDeleteModal = true"
                />
            </ejs-tooltip>
        </div>
        <EditIntroSlideModal
            v-if="showIntroSlidesEditModal"
            :profileFunction="profileFunction"
            @handleDelete="handleDelete"
            @save="handleIntroSlide"
            @cancel="showIntroSlidesEditModal = false"
        />
        <DeleteIntroSlide
            v-if="showIntroSlideDeleteModal"
            @handleDelete="handleDelete"
            @cancel="showIntroSlideDeleteModal = false"
        />
    </div>
</template>

<script>
    import EditIntroSlideModal from './EditIntroSlideModal.vue';
    import DeleteIntroSlide from './DeleteIntroSlide.vue';

    export default {
        name: 'IntroSlideCard',
        components: { EditIntroSlideModal, DeleteIntroSlide },
        props: {
            profileFunction: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showIntroSlidesEditModal: false,
                showIntroSlideDeleteModal: false,
            };
        },
        methods: {
            handleToggle(value, profileFunction) {
                this.$emit('onToggle', { value, profileFunction });
            },
            handleIntroSlide(data) {
                this.showIntroSlidesEditModal = false;
                this.$emit('handleIntroSlide', data);
            },
            handleDelete() {
                this.showIntroSlideDeleteModal = false;
                this.$emit('handleDelete', this.profileFunction);
            },
        },
    };
</script>

<style scoped>
    .profile-function-card-name {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        color: #373cbb;
    }
</style>
