<template>
    <div
        class="profile-function-card d-flex justify-content-between align-items-center cursor-pointer"
        style="min-width: 18rem"
    >
        <div class="d-flex align-items-center gap-2" @click="showEditProfileFunctionModal = true">
            <img
                v-if="iconSrc"
                :src="iconSrc"
                :alt="profileFunction.function_name"
                :title="profileFunction.function_name"
                width="30"
            />
            <span class="profile-function-card-label me-3 profile-function-card-name">{{
                profileFunction.secondary_function_name
            }}</span>
        </div>

        <Toggle
            :id="profileFunction.function_name"
            class="toggle-switch"
            labelClass="switch"
            spanClass="slider"
            :value="profileFunction.active"
            @input="(value) => handleToggle(value, profileFunction)"
        />
        <EditProfileFunctionModal
            v-if="showEditProfileFunctionModal"
            :profileFunction="profileFunction"
            @save="handleSave"
            @cancel="showEditProfileFunctionModal = false"
        />
    </div>
</template>

<script>
    import EditProfileFunctionModal from './EditProfileFunctionModal.vue';

    const functionIconMap = {
        'Track Milestone': require('@/assets/px/mobex-health-plus-dashboard/track_milestone.svg'),
        'Mood Tracker': require('@/assets/px/mobex-health-plus-dashboard/mood_tracker.svg'),
        'Education/Resources': require('@/assets/px/mobex-health-plus-dashboard/education_resources.svg'),
        Medication: require('@/assets/px/mobex-health-plus-dashboard/medication.svg'),
        Vital: require('@/assets/px/mobex-health-plus-dashboard/vital.svg'),
        'Transition to S+P': require('@/assets/px/mobex-health-plus-dashboard/transition_to_S+P.svg'),
        'Find Provider': require('@/assets/px/mobex-health-plus-dashboard/find_provider.svg'),
        'News and updates': require('@/assets/px/mobex-health-plus-dashboard/news_updates.svg'),
    };

    export default {
        name: 'MHPProfileFunctionCard',
        props: {
            profileFunction: {
                type: Object,
                required: true,
            },
        },
        components: { EditProfileFunctionModal },
        data() {
            return { showEditProfileFunctionModal: false };
        },
        methods: {
            handleToggle(value, profileFunction) {
                this.$emit('onToggle', { value, profileFunction });
            },
            handleSave(data) {
                this.showEditProfileFunctionModal = false;
                this.$emit('save', data);
            },
        },
        computed: {
            iconSrc() {
                return functionIconMap[this.profileFunction.function_name] || null;
            },
        },
    };
</script>

<style scoped>
    .profile-function-card-name {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        color: #373cbb;
        text-transform: capitalize;
    }
</style>
