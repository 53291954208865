<template>
    <div class="page-body education-feed-modal">
        <NoButtonModal :open="true">
            <div class="modal-list-third-party-app">
                <div
                    class="modal-content-header modal-list-third-party-app d-flex justify-content-between align-items-center ps-4 pe-2 py-1"
                >
                    <div class="w-100 d-flex justify-content-between align-items-center">
                        <span class="label-app-list-header"> Education Feed </span
                        ><button @click="addNew" type="button" class="no-bg btn-info px-4 mx-4" v-if="!isAddField">
                            Add Item
                        </button>
                    </div>
                </div>
                <template v-if="isAddField && !loading">
                    <div class="d-flex px-5 w-80 align-items-center pt-2">
                        <label style="font-size: 1rem" for="sorting"> Use External Link </label>
                        <Toggle
                            id="sorting"
                            class="toggle-switch"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            v-model="rssDetails.isExternalUrl"
                        />
                    </div>

                    <div class="m-4 py-4" style="min-height: 10rem">
                        <div v-if="rssDetails.isExternalUrl" class="d-flex flex-column" style="gap: 20px">
                            <div class="d-flex align-items-center">
                                <label class="me-3 d-flex add-new-app-label w-20 justify-content-end" for="title">
                                    Feed Title*
                                </label>
                                <input
                                    class="w-70"
                                    type="text"
                                    name="title"
                                    id="education-title"
                                    placeholder="Enter Feed Title"
                                    v-model="rssDetails.title"
                                />
                            </div>
                            <div class="d-flex align-items-center">
                                <label class="me-3 d-flex add-new-app-label w-20 justify-content-end" for="url">
                                    Feed URL*
                                </label>
                                <input
                                    class="w-70"
                                    type="text"
                                    name="url"
                                    id="url"
                                    placeholder="Enter Feed URL"
                                    v-model="rssDetails.url"
                                />
                            </div>
                        </div>
                        <div v-else class="d-flex align-items-center">
                            <label class="me-3 d-flex add-new-app-label w-20 justify-content-end" for="rss">
                                Select Feed*
                            </label>
                            <Multiselect
                                id="rss"
                                track-by="id"
                                label="feedtitle"
                                class="w-70"
                                placeholder="Search item..."
                                v-model="selected_rss"
                                :options="rss_list"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                            />
                        </div>
                    </div>

                    <div class="section-footer add-new-app-form my-4">
                        <div class="d-flex justify-content-center" style="bottom: 10%">
                            <button type="button" class="primary btn-save" @click="saveRssFeed">Add</button>
                            <button type="button" class="no-bg btn-cancel px-4" @click="isAddField = false">
                                Go Back
                            </button>
                        </div>
                    </div>
                </template>
                <template v-else-if="!loading">
                    <div v-if="feedList.length == 0" class="mx-5">Add Feed items to show</div>
                    <div v-else v-for="(feed, index) in feedList" :key="index">
                        <div class="profile-listing-body d-flex mx-5 justify-content-between align-items-center">
                            <!-- <ejs-tooltip
                                v-if="feedList.length > 1"
                                class="tooltipcontainer"
                                content="Reorder"
                                target="#target"
                                cssClass="customtooltip"
                                position="BottomRight"
                            >
                                <img id="target" src="@/assets/px/drag-and-drop/drag_and_drop_icon.svg" alt="" />
                            </ejs-tooltip> -->
                            <span
                                class="w-70 label-app-list-header"
                                style="
                                    text-overflow: ellipsis;
                                    text-wrap: nowrap;
                                    overflow: hidden;
                                    text-transform: capitalize;
                                "
                                >{{ feed.title }}</span
                            >
                            <div class="d-flex">
                                <ejs-tooltip
                                    class="tooltipcontainer"
                                    content="Edit"
                                    target="#target"
                                    cssClass="customtooltip"
                                    position="BottomRight"
                                >
                                    <span
                                        id="target"
                                        class="material-symbols-outlined icon-edit me-2"
                                        role="button"
                                        @click="editFeed(feed)"
                                    >
                                        edit_square
                                    </span>
                                </ejs-tooltip>
                                <ejs-tooltip
                                    class="tooltipcontainer"
                                    content="Delete"
                                    target="#target"
                                    cssClass="customtooltip"
                                    position="BottomRight"
                                >
                                    <span
                                        id="target"
                                        class="material-symbols-outlined icon-delete"
                                        role="button"
                                        @click="deleteFeed(feed.id)"
                                    >
                                        delete
                                    </span>
                                </ejs-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="section-footer add-new-app-form my-4">
                        <div class="d-flex justify-content-center" style="bottom: 10%">
                            <button type="button" class="primary btn-save" @click="saveGenericModule">Save</button>
                            <button type="button" class="no-bg btn-cancel px-4" @click="handleCancel">Cancel</button>
                        </div>
                    </div>
                </template>
            </div>

            <div
                v-if="loading"
                class="d-flex flex-column justify-content-center align-items-center loader-main"
                style="min-height: 25rem"
            >
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { dashboardBuilder, memberProfile, rssFeeds, starkidEducation, education } from '@/util/apiRequests';

    const initialFunctionInfoState = {
        url: '',
        color: '',
        textColor: '',
        primaryColor: '',
        secondaryColor: '',
        isSensitive: false,
        enableQR: false,
        embeddedDashboardId: '',
        embeddedDashboardName: '',
        embeddedCompanyId: null,
        embeddedCompanyName: '',
        lumecatitle: '',
        location: '',
        selectedSchemeOption: 'primary_colors',
        phoneNumbers: [],
        file_id: null,
        document_id: null,
        sensitiveScreenTimeout: 0,
        sensitiveAlertTimeout: 0,
        enabledtms: false,
        dtmsSettings: [],
        title: 'Education',
        assigned_to: 'Education',
        isEmbedded: false,
        isMaster: false,
    };

    const initialRssDetails = {
        id: null,
        rssfeedid: null,
        status: 0,
        defaultpinstatus: 0,
        url: '',
        link: '',
        title: '',
        type: 'rssfeed',
        isExternalUrl: false,
    };

    export default {
        name: 'EducationFeedEditmodal',
        components: { NoButtonModal },
        data() {
            return {
                loading: true,
                isAddField: false,
                selected_rss: '',
                feedList: [],
                all_rss_list: [],
                rss_list: [],
                rssDetails: { ...initialRssDetails },
                functionInfo: { ...initialFunctionInfoState },
            };
        },
        props: {
            clickedProfileFunction: {
                type: String,
                required: true,
            },
            position: {
                type: String,
                required: true,
            },
            applicationFrame: {
                type: String,
                required: true,
            },
            pageData: {
                type: Object,
            },
            dashboardId: {
                type: Number,
            },
        },
        methods: {
            async fetchApplications() {
                try {
                    const endpoint = memberProfile.getSpecificGenericFunction(
                        this.clickedProfileFunction,
                        this.position,
                        this.dashboardId
                    );
                    const functionResponse = await this.$api.get(endpoint);

                    if (!functionResponse) {
                        throw 'e';
                    }

                    if (functionResponse?.data?.info?.length) {
                        this.functionInfo = functionResponse?.data?.info[0];
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch Data');
                }
            },
            async getRssList() {
                try {
                    let list = await this.$api.get(rssFeeds.getRssList());
                    this.all_rss_list = list.data.info;
                } catch (err) {
                    this.$toasted.error('Failed to fetched RSS List');
                }
            },
            async getEducationById() {
                try {
                    const isStarkidApp = ['star-kid', 'mobex-health-plus'].includes(this.applicationFrame);
                    const appFrame = this.applicationFrame === 'star-kid' ? 'starkid' : 'mobexplus';
                    const endpoint = isStarkidApp
                        ? starkidEducation.getEducationById(this.dashboardId, appFrame)
                        : education.getEducationById(this.dashboardId);

                    const response = await this.$api.get(endpoint);
                    if (response?.data?.success) {
                        this.feedList = response.data.message;
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch feed details');
                }
            },
            async deleteFeed(id) {
                try {
                    const isStarkidApp = ['star-kid', 'mobex-health-plus'].includes(this.applicationFrame);
                    const appFrame = this.applicationFrame === 'star-kid' ? 'starkid' : 'mobexplus';
                    const endpoint = isStarkidApp
                        ? starkidEducation.deleteEducationResource(id, appFrame)
                        : education.deleteEducationResource(id);

                    await this.$api.delete(endpoint);
                    await this.getEducationById();
                } catch (err) {
                    this.$toasted.error('Failed to delete RSS');
                }
            },
            async saveRssFeed() {
                const { isExternalUrl, url, title, id } = this.rssDetails;

                if (isExternalUrl && (!url || !title)) {
                    return this.$toasted.error('Please fill all required fields');
                }
                if (!isExternalUrl && !this.selected_rss) {
                    return this.$toasted.error('Please select any feed');
                }

                const data = {
                    id: id || null,
                    type: isExternalUrl ? 'hyperlink' : 'rssfeed',
                    title: isExternalUrl ? title : this.selected_rss.feedtitle,
                    link: isExternalUrl ? url : undefined,
                    rssfeedid: !isExternalUrl ? this.selected_rss.id : undefined,
                    dashboard_id: this.dashboardId,
                    defaultpinstatus: false,
                };

                const isStarkidApp = ['star-kid', 'mobex-health-plus'].includes(this.applicationFrame);
                const appFrame = this.applicationFrame === 'star-kid' ? 'starkid' : 'mobexplus';
                const endpoint = isStarkidApp
                    ? starkidEducation[!id ? 'addEducation' : 'updateEducation'](id || appFrame)
                    : education[!id ? 'addEducation' : 'updateEducation'](id);

                await this.$api[!id ? 'post' : 'put'](endpoint, data);

                this.rssDetails = { ...initialRssDetails };

                await this.getEducationById();
                this.isAddField = false;
            },
            async saveGenericModule() {
                try {
                    if (!this.feedList.length) {
                        this.$toasted.error('Atleast one item should be added');
                        return;
                    }
                    if (!this.functionInfo.id) {
                        const endpoint = memberProfile.createGenericModule();
                        const saveFunctionResponse = await this.$api.post(endpoint, {
                            ...this.functionInfo,
                            assigned_to: this.clickedProfileFunction,
                            isMaster: this.pageData?.page2?.isMaster,
                            position: this.position,
                            dashboard_assigned: this.dashboardId,
                            application: this.applicationFrame,
                            issaved: 0,
                        });
                        if (!saveFunctionResponse.data.success) {
                            throw saveFunctionResponse.data.error;
                        }
                    }
                    this.$toasted.success(
                        `Education Module ${this.functionInfo.id ? 'updated' : 'added'} successfully !`
                    );
                    this.$emit('cancel');
                } catch (err) {
                    const errMsg = err ?? 'Failed to add Module';
                    this.$toasted.error(errMsg);
                }
            },
            addNew() {
                this.isAddField = true;
                this.selected_rss = '';
                this.rssDetails = { ...initialRssDetails };
                const rssFeedIds = new Set(this.feedList.map((item) => item.rssfeedid).filter(Boolean));
                this.rss_list = this.all_rss_list.filter((item) => !rssFeedIds.has(item.id));
            },
            editFeed(data) {
                this.isAddField = true;

                this.rssDetails = {
                    ...data,
                    url: this.rssDetails.link,
                    isExternalUrl: this.rssDetails.type === 'hyperlink',
                };

                if (this.rssDetails.rssfeedid) {
                    this.selected_rss = this.all_rss_list.find((item) => item.id === this.rssDetails.rssfeedid) || '';
                }

                const rssFeedIds = new Set(
                    this.feedList
                        .filter((item) => data.rssfeedid !== item.rssfeedid)
                        .map((item) => item.rssfeedid)
                        .filter(Boolean)
                );

                this.rss_list = this.all_rss_list.filter((item) => !rssFeedIds.has(item.id));
            },
            handleCancel() {
                this.isAddField = false;
                this.$emit('cancel');
            },
        },
        async created() {
            await this.fetchApplications();
            await this.getRssList();
            await this.getEducationById();
            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        font-size: 18px;
        color: #2e3343;
        margin: 0 0 20px;
        background-color: #f1f7ff;
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
    .btn-info,
    .btn-info:hover {
        border-radius: 10px;
        border: 2px solid #5155c2;
        color: #5155c2;
    }
</style>

<style>
    .education-feed-modal .class-label {
        color: #666c6d;
        font-size: 1rem;
        font-weight: 600;
    }
</style>
